import * as React from "react";
import { Link } from "gatsby";
import Layout from "../../components/Layout";
import DownloadBox from "../../components/DownloadBox";
import { FocusAreaIcon } from "../../components/svg";
import Seo from "../../components/Seo";
import FintechId from "../../images/fintech-id.png";

const FintechPage = () => (
  <Layout>
    <Seo title="Fintech" />
    {/* Sub Header image */}
    <div className="_fintech_hero mt-32">
      <div className="flex">
        <FocusAreaIcon icon="fintech" className="mx-auto w-auto h-40 sm:h-80 -mt-20 text-yellow-800 fill-current" />
      </div>

      {/* Sub Header intro text */}
      <div className="container pt-8 pb-16 sm:pt-32 sm:pb-56 text-white">
        <h1 className="text-4xl sm:text-7xl leading-tight sm:leading-tight font-bold text-center">
          <span className="whitespace-nowrap">
            <span className="text-yellow-800 text-5xl sm:text-8xl pr-1 sm:pr-2">[</span>Fintech
          </span>{" "}
          <span className="whitespace-nowrap">
            Solutions<span className="text-yellow-800 text-5xl sm:text-8xl pl-1 sm:pl-2">]</span>
          </span>
        </h1>
        <p className="text-center text-xl leading-snug sm:text-3xl sm:leading-normal pt-8 sm:pt-16">Levercode’s Approach to Fintech</p>
      </div>
    </div>
    {/* Content */}
    <div className="container max-w-screen-xl sm:text-2xl pt-8 pb-16 sm:pt-32 sm:pb-48">
      <p>
        Financial Management and Services are areas that have reaped tremendous benefits from technological advancements. Besides making it faster and more convenient to transfer funds from one entity to another, digital currencies (such as Bitcoin,
        Ethereum etc.) have opened a whole new array of opportunities for the transferring of value, ownership, and accumulation of wealth.
      </p>

      <p className="_p _bold">
        In order to prove ownership of digital assets, Levercode has created LeverID, a Post-Quantum Capable Digital Identity and Signature Platform that covers the fintech technological vertical, and is applicable to all other verticals in the economy.
      </p>

      <p className="_p">
        Integrating a digital identity solution such as LeverID into your system enables users to have <strong>a single digital identity</strong>, which can be utilised to authenticate users in different environments, reducing the need for manual labour
        and in turn giving you the possibility to <strong>securely authenticate</strong> your employees and clients. 
      </p>

      <p className="_p">
        Although <strong>digital signatures</strong> give a noticeable effect to your client’s experience in authenticating and committing proven transactions, it also has an effect on your internal <strong>systems communication integrity</strong>. Imagine
        having an identity related to every movement on data in your mission-critical systems. Being able to reduce the risk of an insider attack in this way is well worth the investment.
      </p>

      <p className="_p">
        <img src={FintechId} alt="Levercode Digital Identity" />
      </p>

      <h2 className="_h2 mt-12 sm:mt-24">Directed Acyclic Graph (DAG)</h2>

      <p className="_p">
        DAG is another layer of added security that can be achieved through the logging of events. An <strong>immutable log</strong> is a reliable tool to mitigate insider attacks from altering log data within a company by having a DAG-based logging
        system. Witnesses are depended on as trusted nodes committed to tracking events. Any event that is logged without Witness nodes is considered an untrusted event and is discarded from the log ledger.
      </p>

      <p className="_p _bold">
        With extensive experience in creating and managing large payment systems and their relevant subsystems, Levercode can provide fully functional Proof of Stake DAG-based digitised payment systems and virtual payment instruments.
      </p>

      <p className="_p">
        Initial designs and UI components are custom-made for clients in order to brand the product to their requirements. Levercode is able to provide the client with a custom configuration enabled by the functionality of the DAG system. Configuring the
        DAG network has a large, measurable impact on the end result of the product. It is possible to configure a plethora of parameters to meet the customer’s needs.
      </p>

      <p className="_p">
        High speed, global coverage and ease of making transaction in digital or virtual form have added new critical issues to the radar, such as <strong>reliability, security, transparency, legality, and integrity</strong> of the environment in which
        transactions take place.  It is important to secure that only transactions that are based on the willful action of the owner of the asset and are in <strong>compliance with laws and regulations (such as GDPR, KYC, AML etc.)</strong> are executed,
        while others are put on hold or blocked. This is possible only when the system is intelligent enough to filter out permitted and prohibited transactions based on risk criteria.
      </p>

      <p className="_p">
        As important as the overall environment is, the technology platform is used for executing and recording events and transactions. That sets <strong>very high standards for systems designed by Levercode</strong>. Such systems must be reliable, work
        swiftly, provide <strong>protection against attack vectors</strong>, as well as proof of transactions. The utilisation of digital signatures, Blockchain, DAG or other forms of Distributed Ledger Technology has proved instrumental in achieving this
        goal and providing provenance of data.
      </p>

      <div className=" bg-gray-100 p-6 sm:p-16 mt-12 sm:mt-24">
        <h3 className="text-blue-800 text-2xl sm:text-4xl sm:leading-snug">In the Fintech vertical, Levercode creates value by:</h3>

        <ul className="list-disc leading-relaxed sm:leading-normal ml-4 sm:ml-16 sm:mt-12">
          <li className="mt-4 sm:mt-8">Integrating LeverID, a Post-Quantum capable digital authentication and signature platform into your existing data system</li>
          <li className="mt-4 sm:mt-8">Providing immutable logging and proof of events by the implementation of Blockchain, DAG or other DLT</li>
          <li className="mt-4 sm:mt-8">White labelling and creating DAG-based messaging systems and wallets for executing transactions and recording the events</li>
          <li className="mt-4 sm:mt-8">Designing your platform for effective launch and governance of virtual currency</li>
          <li className="mt-4 sm:mt-8">Securing compliance of your data systems with the relevant regulatory environment</li>
          <li className="mt-4 sm:mt-8">Offering excellent customer service in setting up the systems and keeping them operational and updated over time</li>
        </ul>
      </div>

      <p className="_p">
        Please{" "}
        <Link to="/contact" className="_link font-bold">
          contact us
        </Link>{" "}
        to learn more about Levercode’s approach to building Digital Society and the ways we can build value for your country or organization. 
      </p>

      <DownloadBox text="Download PDF whitepaper – Fintech Solutions" url="https://levercode.com/downloads/fintech-solutions-by-levercode.pdf" />
    </div>
  </Layout>
);

export default FintechPage;
